const global = {
  api: {
    baseURL: "https://api.nestorvasconcelos.com.br",
    // baseURL: "https://apiclinicas.prolins.com.br",
    // baseURL:
    //   "https://bitter-wildflower-19194.pktriot.net/nestor_backend_api/public",
    // baseURL: "http://localhost/nestor_api/public",
  },
  consultas: {
    tempoMinimo: 15,
    idTipoProcedimentoConsulta: 5,
  },
  tiposProcedimentos: {
    consulta: 5,
    cirurgia: 6,
    compromissoPessoal: 7,
  },
  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    { id: 2, description: "medico" },
    { id: 3, description: "assistente" },
    { id: 4, description: "paciente" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      warning: "#FFCA28",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};
export { global };
